import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

//Hooks
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions.js";
import {
  fetchContactLabels,
  updateContact,
} from "~/redux/actions/contacts-actions.js";

// Utils
import request from "~/utils/request.js";
import { EMAIL_REGEX } from "~/utils/constants.js";
import { dateFormat } from "~/utils/date-format.js";
import { MESSAGES } from "~/utils/message.js";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation.js";

// Components
import MultiSelect from "~/components/General/MultiSelect/index.jsx";
import CreateTagModal from "~/components/Settings/Tags/CreateTag/index.jsx";

// MUI Components
import {
  Box,
  Button,
  Grid,
  Typography,
  Chip,
  CircularProgress,
  Input,
  InputLabel,
} from "@mui/material";

// MUI Icons
import { PlusIconCIcon } from "~/assets/images";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styles
import "./styles.scss";

const UpdateContact = (props) => {
  const { propertyAddress, setPropertyAddress, isPropertyAddressEnabled, setIsEditing } =
    props;
  const [contact, setContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loader, setLoader] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tags = useSelector((state) => state.contactsReducers.labels);

  // Sort the tags alphabetically
  const sortedTags = [...tags].sort((a, b) => a?.title.localeCompare(b?.title));

  const tagsOptions = sortedTags
    ?.map((tag) => {
      return {
        id: tag.id,
        title: tag.title,
      };
    })
    .concat({
      id: "-1",
      title: "Create New Tag",
      custom: true,
      icon: PlusIconCIcon,
      func: () => setCreateTagModal(true),
    });

  const contactId = params.id;

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .test({
        test: function (value) {
          const { companyName } = this.parent;
          return !!value || !!companyName;
        },
        message: MESSAGES.CONTACTS.EDIT.FIRST_NAME_OR_COMPANY,
      })
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_LIMIT)
      .notRequired(),
    companyName: yup
      .string()
      .max(100, MESSAGES.CONTACTS.EDIT.COMPANY_MAX_LENGTH)
      .test({
        test: function (value) {
          const { firstName } = this.parent;
          return !!value || !!firstName;
        },
        message: MESSAGES.CONTACTS.EDIT.FIRST_NAME_OR_COMPANY,
      })
      .notRequired(),
    lastName: yup
      .string()
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_LIMIT)
      .notRequired(),
    phoneNo: yup
      .string()
      .max(20, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.PHONE_LIMIT)
      .nullable(true),
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL)
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNo: "",
      email: "",
    },
    validationSchema: validationSchema,
  });

  const goBackToPreviousRoute = () => navigate("/contacts");

  const removeTag = (tag) => {
    setSelectedTags((selectedTags) =>
      selectedTags.filter((item) => {
        return item !== tag;
      })
    );
  };

  const fetchContactData = async () => {
    try {
      setIsLoading(true);
      const response = await request.get(`contacts/${contactId}`, {});
      if (response.status === 200) {
        setContact(response.data.data);
      }
    } catch (error) {
      goBackToPreviousRoute();
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateContact = async (event) => {
    try {
      event.preventDefault();
      if (
        formik.values.firstName &&
        /^[a-zA-Z ]*$/.test(formik.values.firstName)
      ) {
        delete formik.errors.firstName;
      }

      if (formik.values.companyName && formik.values.companyName.length < 100) {
        delete formik.errors.companyName;
      }

      if (!formik.values.companyName) {
        delete formik.errors.companyName;
      }

      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = formik.values;
      payload.id = contactId;
      selectedTags.length ? (payload.tags = selectedTags) : undefined;
      payload.email = payload?.email?.length ? payload?.email : null;
      if (isPropertyAddressEnabled) {
        payload.propertyAddress = propertyAddress?.address;
        payload.propertyCity = propertyAddress?.city;
        payload.propertyState = propertyAddress?.state;
        payload.propertyZip = propertyAddress?.zip;
      }
      setLoader(true);
      const response = await dispatch(updateContact(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        setIsEditing(false);
      } else {
        dispatch(
          failure(
            response?.data?.data?.errors[0]?.msg || response?.data?.message
          )
        );
      }
    } catch (error) {
      dispatch(failure(error.message));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (Object.keys(contact).length) {
      Object.keys(formik.initialValues).forEach((fieldName) => {
        formik.setFieldValue(fieldName, contact[fieldName]);
        formik.setFieldTouched(fieldName, false);
        formik.touched[fieldName] = false;
      });
      if (contact.ContactLabels.length) {
        setSelectedTags(() => [
          ...contact.ContactLabels.map((tag) => tag.title),
        ]);
      }
    }
  }, [contact]);

  useEffect(() => {
    resetValidation();
    formik.handleReset();
    Object.keys(formik.initialValues).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, false);
      formik.touched[fieldName] = false;
    });
    fetchContactData();
    dispatch(fetchContactLabels());
  }, []);

  return (
    <Grid container className="editDetailsGrid">
      <Grid item lg={2} md={2} sm={3} xs={3}>
        <Button className="contactsBackBtn" onClick={goBackToPreviousRoute}>
          <ArrowBackIcon />
          {MESSAGES.CONTACTS.CONTACT_BACK_ARROW}
        </Button>
      </Grid>
      <Grid item lg={8} md={8} sm={6} xs={6}>
        <Box className="editDetailsHeading">
          <Typography variant="h4">
            {MESSAGES.CONTACTS.CONTACT_DETAIL_TITLE}
          </Typography>
          {isLoading ? (
            <Box
              sx={{
                height: "100%",
                minHeight: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{
                  color: "#ED5C2F",
                  width: "50px !important",
                  height: "50px !important",
                }}
              />
            </Box>
          ) : (
            <form className="updateFormWrapper" onSubmit={onUpdateContact}>
              <Box>
                <div className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.EDIT.FIRST_NAME_LABEL}
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.CONTACTS.EDIT.FIRST_NAME_LABEL}
                    name="firstName"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.firstName && formik.touched.firstName
                        ? "invalid editContactField"
                        : "editContactField"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.firstName}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.firstName}
                    </Typography>
                  )}
                </div>

                <div className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.EDIT.LAST_NAME_LABEL}
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.CONTACTS.EDIT.LAST_NAME_LABEL}
                    name="lastName"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.lastName && formik.touched.lastName
                        ? "invalid editContactField"
                        : "editContactField"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.lastName}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.lastName}
                    </Typography>
                  )}
                </div>

                <div className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.EDIT.COMPANY_LABEL}
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.CONTACTS.EDIT.COMPANY_LABEL}
                    name="companyName"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.companyName && formik.touched.companyName
                        ? "invalid editContactField"
                        : "editContactField"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.companyName}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.companyName}
                    </Typography>
                  )}
                </div>

                <Box mb={2}>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.ADDRESS_1_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.address1 || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.ADDRESS_2_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.address2 || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.CITY_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.city || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.STATE_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.state || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.ZIP_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.zip || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.EDIT.PHONE_LABEL}
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.CONTACTS.EDIT.PHONE_LABEL}
                    name="phoneNo"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.phoneNo && formik.touched.phoneNo
                        ? "invalid editContactField"
                        : "editContactField"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.phoneNo}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                  />
                  {formik.touched.phoneNo && formik.errors.phoneNo && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.phoneNo}
                    </Typography>
                  )}
                </div>
                <Box className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.ADVANCE_FILTERS.TAGS_LABEL}
                  </InputLabel>
                  <MultiSelect
                    component={"tag-create"}
                    options={tagsOptions}
                    selectedValue={selectedTags}
                    setSelectedValue={setSelectedTags}
                    placeHolderText={
                      MESSAGES.CONTACTS.ADVANCE_FILTERS.CREATE_TAG
                    }
                  />
                  {selectedTags.length > 0 && (
                    <Box className="selectedTagsWrapper">
                      {selectedTags.map((selectedTag) => {
                        return (
                          <Box className="tagContent" key={selectedTag}>
                            <Chip className="tagChip" label={selectedTag} />
                            <HighlightOffIcon
                              onClick={() => removeTag(selectedTag)}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>

                <div className="formField">
                  <InputLabel className="label">
                    {MESSAGES.CONTACTS.EDIT.EMAIL_LABEL}
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.CONTACTS.EDIT.EMAIL_LABEL}
                    name="email"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.email && formik.touched.email
                        ? "invalid editContactField"
                        : "editContactField"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.email}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.email}
                    </Typography>
                  )}
                </div>

                <Box mb={2}>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.ADDRESS_STATUS}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.addressStatus || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.TOTAL_MAILED}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{contact?.totalMailed || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.LAST_MAILED_STATUS}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span> {contact?.lastMailedStatus || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.LAST_MAILED_DATE}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={12} xs={12}>
                      <div className="editContent">
                        <span>
                          {dateFormat(contact?.lastMailedDate) || " -"}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.DATE_LABEL}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent">
                        <span>{dateFormat(contact?.createdAt) || " -"}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="staticFields">
                    <Grid item lg={2} md={4} sm={4} xs={12}>
                      <div className="label">
                        {MESSAGES.CONTACTS.EDIT.USE_AS_RETURN_ADDRESS}:
                      </div>
                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                      <div className="editContent" style={{marginLeft: '10px'}}>
                        <span>{contact?.isReturnAddress ? 'Yes' : 'No'}</span>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {isPropertyAddressEnabled && (
                  <>
                    <div className="formField">
                      <InputLabel className="label">
                        {MESSAGES.CONTACTS.EDIT.PROPERTY_ADDRESS}
                      </InputLabel>
                      <Input
                        type="text"
                        placeholder={MESSAGES.CONTACTS.EDIT.PROPERTY_ADDRESS}
                        autoComplete="off"
                        onChange={(event) => {
                          setPropertyAddress((prev) => ({
                            ...prev,
                            address: event.target.value,
                          }));
                        }}
                        className="editContactField"
                        value={propertyAddress?.address}
                      />
                    </div>
                    <div className="formField">
                      <InputLabel className="label">
                        {MESSAGES.CONTACTS.EDIT.PROPERTY_CITY}
                      </InputLabel>
                      <Input
                        type="text"
                        placeholder={MESSAGES.CONTACTS.EDIT.PROPERTY_CITY}
                        autoComplete="off"
                        onChange={(event) => {
                          setPropertyAddress((prev) => ({
                            ...prev,
                            city: event.target.value,
                          }));
                        }}
                        className="editContactField"
                        value={propertyAddress?.city}
                      />
                    </div>
                    <div className="formField">
                      <InputLabel className="label">
                        {MESSAGES.CONTACTS.EDIT.PROPERTY_STATE}
                      </InputLabel>
                      <Input
                        type="text"
                        placeholder={MESSAGES.CONTACTS.EDIT.PROPERTY_STATE}
                        autoComplete="off"
                        onChange={(event) => {
                          setPropertyAddress((prev) => ({
                            ...prev,
                            state: event.target.value,
                          }));
                        }}
                        className="editContactField"
                        value={propertyAddress?.state}
                      />
                    </div>
                    <div className="formField">
                      <InputLabel className="label">
                        {MESSAGES.CONTACTS.EDIT.PROPERTY_ZIP}
                      </InputLabel>
                      <Input
                        type="text"
                        placeholder={MESSAGES.CONTACTS.EDIT.PROPERTY_ZIP}
                        autoComplete="off"
                        onChange={(event) => {
                          setPropertyAddress((prev) => ({
                            ...prev,
                            zip: event.target.value,
                          }));
                        }}
                        className="editContactField"
                        value={propertyAddress?.zip}
                      />
                    </div>
                  </>
                )}
              </Box>
            </form>
          )}
        </Box>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={3}
        xs={3}
        sx={{
          marginTop: { md: "0px", sm: "20px", xs: "20px" },
          display: { md: "block", sm: "flex", xs: "flex" },
          justifyContent: { sm: "flex-end", xs: "flex-end" },
        }}
      >
        <Box
          className="detailsActionBtn"
          sx={{
            justifyContent: "flex-end !important",
          }}
        >
          <Button
            type="submit"
            onClick={(() => validateForm(formik), onUpdateContact)}
            className="delBtn"
          >
            {loader ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              <Typography>{MESSAGES.CONTACTS.SAVE_BTN_TEXT}</Typography>
            )}
          </Button>
        </Box>
      </Grid>
      <CreateTagModal
        openModal={createTagModal}
        handleCloseModal={() => setCreateTagModal(false)}
        setSelectedTags={setSelectedTags}
        setTags={true}
      />
    </Grid>
  );
};

export default UpdateContact;
